<template>
  <div class="content-container" :style="containerStyle">
    <div class="mdc-data-table__table-container" v-if="viewIsTable">
      <table class="mdc-data-table__table" :style="tableStyle">
        <thead>
          <tr class="mdc-data-table__header-row">
            <th class="mdc-data-table__header-cell" v-for="(col, colIndex) in tableHeaderColumns" :key="colIndex"
              :style="[tableCellStyle, tableHeaderStyle]"><button class="sort-button"
                @click.prevent="sortTable(colIndex)">
                <span class="underline title-case">{{ col }}</span>&nbsp;
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 320" class="sort-icon"
                  v-if="currentSortedColumnIndex == colIndex && sortOrder" :style="sortIconStyle">
                  <!-- Font Awesome Pro 5.15.3 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) -->
                  <path
                    d="M279.015 243.525h-238c-21.4 0-32.1-25.9-17-41l119-119c9.4-9.4 24.6-9.4 33.9 0l119 119c15.2 15.1 4.5 41-16.9 41z" />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 320" class="sort-icon"
                  v-if="currentSortedColumnIndex == colIndex && !sortOrder" :style="sortIconStyle">
                  <!-- Font Awesome Pro 5.15.3 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) -->
                  <path
                    d="M41 76.475h238c21.4 0 32.1 25.9 17 41l-119 119c-9.4 9.4-24.6 9.4-33.9 0l-119.1-119c-15.1-15.1-4.4-41 17-41z" />
                </svg>
              </button>
            </th>
          </tr>
        </thead>
        <tbody class="mdc-data-table__content">
          <tr v-for="(row, rowIndex) in tableDataRows" :key="rowIndex" :style="tableRowStyle">
            <td class="mdc-data-table__cell" v-for="(col, colIndex) in row" :key="`row-${rowIndex}-col-${colIndex}`"
              :style="[tableCellStyle, tableTextStyle]">
              {{ col }}
            </td>
          </tr>
        </tbody>
      </table>
      <div v-if="dataIsDownloadable" class="table-container-footer" :style="footerStyle">
        <button class="button table-container-footer-button" :style="buttonStyle" @click.prevent="onDownloadCsv"
          @keyup.enter="onDownloadCsv">{{ tr('Download CSV') }}</button>
        <button class="button table-container-footer-button" :style="buttonStyle" @click.prevent="onDownloadTsv"
          @keyup.enter="onDownloadTsv">{{ tr('Download TSV') }}</button>
      </div>
    </div>
    <div class="markdown" v-if="viewIsMarkdown" v-html="mdData" :style="txtStyle">
    </div>
    <div class="bottom-right-button-container" v-if="viewIsMarkdown && dataIsDownloadable">
      <button class="button" :style="buttonStyle" @click.prevent="onDownloadMarkdown" @keyup.enter="onDownloadMarkdown">{{
        tr('Download Markdown')
      }}</button>
    </div>
    <div class="svg" v-if="viewIsSvg" v-html="svgData" :style="svgStyle">
    </div>
    <div class="bottom-right-button-container" v-if="viewIsSvg && dataIsDownloadable">
      <button class="button" :style="buttonStyle" @click.prevent="onDownloadSvg" @keyup.enter="onDownloadSvg">{{
        tr('Download SVG')
      }}</button>
    </div>
    <div v-if="viewIsPdf">
      <VuePdfEmbed :source="pdfData"></VuePdfEmbed>
      <div class="bottom-right-button-container" v-if="dataIsDownloadable">
        <button class="button" :style="buttonStyle" @click.prevent="onDownloadPdf" @keyup.enter="onDownloadPdf">{{
          tr('Download PDF')
        }}</button>
      </div>
    </div>
    <div class="plain-text" v-if="viewIsTxt" :style="txtStyle">
      <pre v-html="txtData"></pre>
      <div class="bottom-right-button-container" v-if="dataIsDownloadable">
        <button class="button" :style="buttonStyle" @click.prevent="onDownloadTxt" @keyup.enter="onDownloadTxt">{{
          tr('Download TXT')
        }}</button>
      </div>
    </div>
  </div>
</template>

<script>
// Copyright (C) dātma, inc™ - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { computed, inject, ref } from 'vue'
import { useStore } from 'vuex'
import { saveAs } from 'file-saver'
import VuePdfEmbed from 'vue-pdf-embed'
import MarkdownIt from 'markdown-it'
import markdownItMermaid from '@md-reader/markdown-it-mermaid'
import markdownItGraphviz from 'markdown-it-graphviz'

import { base64Decode, base64DecodeBytes, base64GzipDecode, base64GzipDecodeBytes, themeColors } from '@/common/shared.js'

import { parseTableData, tableToCsv, tableToTsv } from './tableData'

export default {
  components: {
    VuePdfEmbed,
  },
  props: {
    payload: {
      type: Object,
      required: true,
    },
    sessionId: {
      type: String,
      required: true,
    },
    sessionOwnerId: {
      type: String,
      required: true,
    },
    toolWidthInColumns: {
      type: Number,
      required: true,
    },
    toolHeightInRows: {
      type: Number,
      required: true,
    },
    toolId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const store = useStore()
    const tr = inject('tr')

    const viewIsMarkdown = computed(() => props.payload.type === 'base64-markdown' || props.payload.type === 'base64-gzip-markdown')
    const viewIsPdf = computed(() => props.payload.type === 'base64-pdf' || props.payload.type === 'base64-gzip-pdf')
    const viewIsSvg = computed(() => props.payload.type === 'base64-svg' || props.payload.type === 'base64-gzip-svg')
    const viewIsTable = computed(() => props.payload.type === 'base64-csv' || props.payload.type === 'base64-tsv')
    const viewIsTxt = computed(() => props.payload.type === 'base64-txt')

    const tableInfo = computed(() => parseTableData(props))
    const tableDataRows = computed(() => tableInfo.value.tableDataRows)
    const tableHeaderColumns = computed(() => tableInfo.value.tableHeaderColumns)

    const dataIsDownloadable = computed(() => !!props.payload.downloadable)
    const mdData = computed(() => {
      const markdown = props.payload.type === 'base64-markdown' ? base64Decode(props.payload.data) :
        props.payload.type === 'base64-gzip-markdown' ? base64GzipDecode(props.payload.data) : ''
      if (markdown === '') { return '' }
      const mdi = new MarkdownIt()
      mdi.use(markdownItMermaid)
      mdi.use(markdownItGraphviz)
      return mdi.render(markdown)
    })
    const pdfData = computed(() => {
      return {
        data: (props.payload.type === 'base64-pdf' ? base64DecodeBytes(props.payload.data) :
          props.payload.type === 'base64-gzip-pdf' ? base64GzipDecodeBytes(props.payload.data) : ''),
      }
    })
    const svgData = computed(() => props.payload.type === 'base64-svg' ? base64Decode(props.payload.data) :
      props.payload.type === 'base64-gzip-svg' ? base64GzipDecode(props.payload.data) : '')
    const txtData = computed(() => props.payload.type === 'base64-txt' ? base64Decode(props.payload.data) : '')

    const buttonStyle = computed(() => {
      return {
        backgroundColor: themeColors[store.getters.currentThemeName].buttonBackgroundColor,
        color: themeColors[store.getters.currentThemeName].buttonTextColor,
        border: `${themeColors[store.getters.currentThemeName].buttonBorderColor}`,
      }
    })

    const sortIconStyle = computed(() => {
      return {
        fill: themeColors[store.getters.currentThemeName].tableHeaderTextColor,
      }
    })

    const containerStyle = computed(() => {
      return {
        backgroundColor: themeColors[store.getters.currentThemeName].modalBackgroundColor,
      }
    })

    const svgStyle = computed(() => {
      return {
        '--color': themeColors[store.getters.currentThemeName].toolTextColor,
      }
    })

    const txtStyle = computed(() => {
      return {
        backgroundColor: themeColors[store.getters.currentThemeName].modalBackgroundColor,
        color: themeColors[store.getters.currentThemeName].modalTextColor,
      }
    })

    const footerStyle = computed(() => {
      return {
        backgroundColor: themeColors[store.getters.currentThemeName].modalBackgroundColor,
      }
    })

    const tableStyle = computed(() => {
      return {
        border: `0.5px solid ${themeColors[store.getters.currentThemeName].toolBorderColor}`,
      }
    })

    const tableHeaderStyle = computed(() => {
      return {
        backgroundColor: themeColors[store.getters.currentThemeName].tableHeaderCellColor,
        color: themeColors[store.getters.currentThemeName].tableHeaderTextColor,
      }
    })

    const tableRowStyle = computed(() => {
      return {
        '--tr-even-row-background-color': themeColors[store.getters.currentThemeName].alternatingTableRowsBackgroundColor1,
      }
    })

    const tableCellStyle = computed(() => {
      return {
        border: `0.5px solid ${themeColors[store.getters.currentThemeName].toolBorderColor}`,
        fontFamily: store.getters.font,
      }
    })

    const tableTextStyle = computed(() => {
      return {
        color: themeColors[store.getters.currentThemeName].tableCellTextColor,
      }
    })

    let lastSortedColumnIndex = -1
    let lastSortOrder = true
    const currentSortedColumnIndex = ref(-1)
    const sortOrder = ref(false)

    const compareFunc = (columnIndex, ascending) => {
      if (columnIndex < 0) { return () => 0 }
      return (a, b) => {
        const f = (a, b) => a[columnIndex].localeCompare(b[columnIndex])
        return ascending ? f(a, b) : -f(a, b)
      }
    }

    const sortTable = (index) => {
      if (index !== currentSortedColumnIndex.value) {
        lastSortedColumnIndex = currentSortedColumnIndex.value
        lastSortOrder = sortOrder.value
        sortOrder.value = true
      } else {
        sortOrder.value = !sortOrder.value
      }
      currentSortedColumnIndex.value = index

      const firstCompare = compareFunc(index, sortOrder.value)
      const secondCompare = compareFunc(lastSortedColumnIndex, lastSortOrder)
      const bothCompares = (a, b) => {
        const result1 = firstCompare(a, b)
        if (result1 === 0) { return secondCompare(a, b) }
        return result1
      }
      tableDataRows.value.sort(bothCompares)
    }

    const onDownloadCsv = () => {
      const data = tableToCsv(props, tableInfo.value)
      const blob = new Blob([data], {
        type: 'text/csv;charset=utf-8;',
      })
      saveAs(blob, 'table.csv')
    }

    const onDownloadTsv = () => {
      const data = tableToTsv(props, tableInfo.value)
      const blob = new Blob([data], {
        type: 'text/tsv;charset=utf-8;',
      })
      saveAs(blob, 'table.tsv')
    }

    const onDownloadMarkdown = () => {
      const data = props.payload.type === 'base64-markdown' ? base64Decode(props.payload.data) :
        props.payload.type === 'base64-gzip-markdown' ? base64GzipDecode(props.payload.data) : ''
      const blob = new Blob([data], {
        type: 'text/plain;charset=utf-8;',
      })
      saveAs(blob, 'file.md')
    }

    const onDownloadSvg = () => {
      const data = svgData.value.replaceAll('var(--color)', '#000')
      const blob = new Blob([data], {
        type: 'image/svg+xml;charset=utf-8',
      })
      saveAs(blob, 'image.svg')
    }

    const onDownloadPdf = () => {
      const data = Buffer.from(pdfData.value.data, 'binary')
      const blob = new Blob([data], {
        type: 'application/pdf',
      })
      saveAs(blob, 'document.pdf')
    }

    const onDownloadTxt = () => {
      const data = txtData.value
      const blob = new Blob([data], {
        type: 'text/plain;charset=utf-8;',
      })
      saveAs(blob, 'text.txt')
    }

    return {
      buttonStyle,
      containerStyle,
      currentSortedColumnIndex,
      dataIsDownloadable,
      footerStyle,
      mdData,
      onDownloadCsv,
      onDownloadMarkdown,
      onDownloadPdf,
      onDownloadSvg,
      onDownloadTsv,
      onDownloadTxt,
      pdfData,
      sortIconStyle,
      sortOrder,
      sortTable,
      svgData,
      svgStyle,
      tableCellStyle,
      tableDataRows,
      tableHeaderColumns,
      tableHeaderStyle,
      tableRowStyle,
      tableStyle,
      tableTextStyle,
      tr,
      txtData,
      txtStyle,
      viewIsMarkdown,
      viewIsPdf,
      viewIsSvg,
      viewIsTable,
      viewIsTxt,
    }
  },
}
</script>

<style lang="scss" scoped>
.content-container {
  height: 100%;
  max-width: 100%;
  overflow: auto;
}

.svg {
  width: 100%;
  height: 100%;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  --color: #000; // fallback
}

.mdc-data-table__header-cell:first-child {
  border-top-left-radius: 10px;
}

.mdc-data-table__header-cell:last-child {
  border-top-right-radius: 10px;
}

.mdc-data-table__content>tr:last-child>.mdc-data-table__cell:first-child {
  border-bottom-left-radius: 10px;
}

.mdc-data-table__content>tr:last-child>.mdc-data-table__cell:last-child {
  border-bottom-right-radius: 10px;
}

.mdc-data-table__table {
  border-collapse: separate;
  border-spacing: 0;
  border: 0;
  border-radius: 10px;
  margin: 2rem auto 6rem auto;
  min-width: 70%;
  white-space: nowrap;
  table-layout: fixed;
}

.mdc-data-table__content>tr:nth-child(even) {
  background-color: '#333'; // fallback
  background-color: var(--tr-even-row-background-color);
}

.mdc-data-table__header-cell,
.mdc-data-table__cell {
  text-align: left;
  font-size: 0.875rem;
  letter-spacing: 0.0178571429em;
  padding: 0.5rem;
  padding-left: 16px;
  padding-right: 16px;
}

.mdc-data-table__header-cell {
  font-weight: bolder;
  box-sizing: border-box;
  text-overflow: ellipsis;
  overflow: hidden;
  outline: none;
  height: 56px;
  line-height: 1.375rem;
}

.mdc-data-table__cell {
  font-weight: normal;
  line-height: 1.25rem;
}

.sort-button {
  font: inherit;
  color: inherit;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:hover,
  &:focus,
  &:focus:not(:focus-visible) {
    transform: none;

    &:hover {
      transform: none;
    }
  }
}

.sort-button:hover .underline {
  text-decoration: underline;
}

.sort-icon {
  width: 0.8rem;
}

.title-case {
  text-transform: capitalize;
}

.table-container-footer {
  width: 100%;
  margin: 0;
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.3);
}

.table-container-footer-button {
  margin: 1rem 0;
}

.plain-text {
  margin: 2rem;
}

.markdown {
  margin: 2rem;
  text-align: left;
}

.plain-text pre {
  text-align: left;
  white-space: pre-wrap;
}

.bottom-right-button-container {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 1rem;
}

.button {
  font: inherit;
  font-size: 1rem;
  padding: .75rem;
  min-width: 100px;
  line-height: 1.5;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 2rem;
  cursor: pointer;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(38 143 255 / 50%);
  }
}
</style>
